import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Styled from 'styled-components';
import { Col } from 'reactstrap';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import breakpoint from 'styled-components-breakpoint';
import { Location } from '@reach/router';
import { updateState } from 'actions/actions';

import Steps from './Steps';
import Help from './Help';
import Heading from './Heading';

const ProgressBarContainer = Styled.div`
  display: none;
  position: ${props => props.fixed ? 'fixed' : 'absolute'};
  left: 0;
  ${props => props.fixed ? 'top: 0' : 'bottom: 0'};
  ${breakpoint('sm')` 
    display: block;
    width: 200px;
  `}
  ${breakpoint('md')` 
    max-width: 500px;
    min-width: 250px;
    width: 25vw;
  `}
  z-index: 1;
`;

const StyledCol = Styled(Col)`
  background-color: ${props => props.theme.main.colors.neutral.white};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 250px;
  z-index: 1;
`;

export default ({updateProgressBarHeight}) => {
  const dispatch = useDispatch();  
  const progressBarRef = useRef(null);
  const [isFixed, setIsFixed] = useState(true);
  const [route, setRoute] = useState(null);

  useScrollPosition(({ prevPos, currPos }) => {
    const innerHeight = window.innerHeight;
    const progressBarHeight = progressBarRef.current.clientHeight;
    const documentHeight = document.body.clientHeight;

    if(innerHeight < progressBarHeight) {
      if(((documentHeight - progressBarHeight) + currPos.y) < 0 && currPos.y !== 0) {
        setIsFixed(false);
      }
      else {
        setIsFixed(true);
      }
    } else {
      setIsFixed(true);
    }
  });

  useEffect(() => {
    const handleResize = () => updateProgressBarHeight(progressBarRef.current.clientHeight);

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(updateState({
      currentRoute: route
    }));
  }, [route]);

  return (
    <ProgressBarContainer ref={progressBarRef} fixed={isFixed}>
      <Location>
        {({navigate, location}) => {          
          setRoute(location.pathname.replace(/\/+$/, ''));
        }}
      </Location>
      <StyledCol>        
        <Heading />
        <Steps route={route} />
        <Help route={route} />
      </StyledCol>
    </ProgressBarContainer>);
}