import { navigate } from 'gatsby';

export const handleResponse = (response) => {
  if(!response.ok) {
    throw {
      response: response.json(),
      error: new Error() 
    }
  }
  return response.json();
}

export const catchError = (error) => {
  if(error.response) {
    error.response.then(message => {    
      const errorAlert = message.Data.ErrorAlert;
      if(errorAlert.IsError) {
        navigate('error', { replace: true, state: {errorCode: errorAlert.MainError.Key}});
      }
    }).catch(error => {
      navigate('error', { replace: true, state: {errorCode: 100}});
    });
  } 
  else {
    navigate('error', { replace: true, state: {errorCode: 100}});
  }
}

export const handleError = (response) => {
  if(response.errorAlert && response.errorAlert.isError) {          
    navigate('error', { replace: true, state: {errorCode: response.errorAlert.mainError.key, alerts: response.errorAlert.isAlert ? response.errorAlert.alerts : null }});
  }
  else {
    navigate('error', { replace: true, state: {errorCode: 100}});
  }
}