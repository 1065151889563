import React from 'react';
import PropTypes from 'prop-types';
import styled, { css }  from 'styled-components';

import EditIcon from 'images/edit.inline.svg';

const StyledBox = styled.div`
  position: relative;
  background-color: white;
  padding: 32px 24px;
  margin-bottom: 32px;
  max-width: 100%;

  p {
    ${props => props.theme.main.fonts.body.normal};
    color: ${props => props.theme.main.colors.grey.base};

    margin: 15px 0;
  }

  ${props => props.shadow && css`
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);`
  }
`;

const Edit = styled.div`
  display: ${props => props.visible ? 'block' : 'none'};
  cursor: pointer;
  position: absolute;
  right: 26px;
  z-index: 1;
`;

const Box = ({children, name=null, edit=false, editClick, className, shadow=false}) => (
  <StyledBox className={className} shadow={shadow} data-testid={name}>
    <Edit visible={edit} onClick={editClick}>      
      <EditIcon />
    </Edit>
    {children}
  </StyledBox>
);

Box.propTypes = {
  children: PropTypes.node.isRequired,
  edit: PropTypes.bool,
  editClick: PropTypes.func,
  shadow: PropTypes.bool
}

export default Box;