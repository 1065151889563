import React from 'react';
import PropTypes from 'prop-types';
import styled, { css }  from 'styled-components';
import { Button as BootstrapButton } from 'reactstrap';

const StyledButton = styled(BootstrapButton)`
  ${props => props.theme.main.fonts.body.normal};
  border-radius: 0;
  min-width: 120px;
  height: 48px;
  ${props => props.color === 'primary' && css`
    background-color: ${props => props.theme.main.colors.primary.base};
    border: 1px solid ${props => props.theme.main.colors.primary.dark1};
    color: white;

    &:active, &:not(:disabled):not(.disabled):active {
      background-color: ${props => props.theme.main.colors.primary.light1};
      border: 2px solid ${props => props.theme.main.colors.primary.light1};
      color: ${props => props.theme.main.colors.neutral.white};
      box-shadow: none;
    }
    &:hover, &:focus, :not(:disabled):not(.disabled):active:focus {
      background-color: ${props => props.theme.main.colors.primary.dark1};
      border: 2px solid ${props => props.theme.main.colors.primary.dark1};
      color: ${props => props.theme.main.colors.neutral.white};
      box-shadow: none;
    }

    ${props => props.disabled && css`
      &:disabled {
        border: none;
        color: ${props => props.theme.main.colors.grey.base};
        background: ${props => props.theme.main.colors.neutral.highlight};
        cursor: not-allowed;
      }
    `}
  `}

  ${props => props.color === 'secondary' && css`
    background-color: transparent;
    border: 1px solid ${props => props.theme.main.colors.primary.base};
    color: ${props => props.theme.main.colors.primary.base};

    &:active, &:not(:disabled):not(.disabled):active {
      background-color: ${props => props.theme.main.colors.primary.light1};
      border: 2px solid ${props => props.theme.main.colors.primary.light1};
      color: ${props => props.theme.main.colors.neutral.white};
      box-shadow: none;
    }
    &:hover, &:focus, :not(:disabled):not(.disabled):active:focus {
      background-color: transparent;
      border: 2px solid ${props => props.theme.main.colors.primary.dark1};
      color: ${props => props.theme.main.colors.primary.dark1};
      box-shadow: none;
    }

    ${props => props.disabled && css`
      &:disabled {
        border: 2px solid ${props => props.theme.main.colors.neutral.line};
        color: ${props => props.theme.main.colors.grey.base};
        background: ${props => props.theme.main.colors.neutral.offwhite};
        cursor: not-allowed;
      }
    `}
  `}   

  ${props => props.align === 'bottom' && css`
    margin: 0px auto;
    margin-top: auto;
  `}
`;

const Button = ({children, color = 'primary', type = 'button', onClick, disabled = false, align, className, name=null}) => (
  <StyledButton color={color} type={type} onClick={onClick} disabled={disabled} align={align} className={className} aria-disabled={disabled} data-testid={name}>{children}</StyledButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  align: PropTypes.string,
  type: PropTypes.string
}

export default Button;