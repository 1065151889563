import React from 'react';
import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { updateLoanType } from 'actions/actions';
import styled from 'styled-components';
import Slider from 'react-slick';
import breakpoint from 'styled-components-breakpoint';
import { formatPercent } from 'utils/format';

import ExternalLink from 'images/external.link.inline.svg';

import Header from 'components/Common/Layout/Header'; 
import Box from 'components/Common/Layout/Box'; 
import Button from 'components/Common/Form/Button'; 
import Footnote from 'components/Common/Layout/Footnote';
import Disclaimer from 'components/Common/Layout/Disclaimer';

import 'slick-carousel/slick/slick.css';

const Heading = styled.h2`
  ${props => props.theme.main.fonts.body.bold};
  color: ${props => props.theme.main.colors.grey.dark2};
  font-size: 12.8px;
  line-height: 19px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0;
  sup {
    top: -0.3em;
    font-family: arial, sans-serif;
  }
`;

const Amount = styled.div`
  ${props => props.theme.main.fonts.application.h2};
  text-align: center;
  margin-bottom: -20px;
  background-color: white;
  z-index: 1;
  margin-left: 14px;
  margin-right: 14px;
  white-space: nowrap;
  letter-spacing: -1px;
  ${breakpoint('md')`
    letter-spacing: 0;
  `}
  span {
    ${props => props.theme.main.fonts.application.h3};
  }
`;

const Description = styled.div`
  ${props => props.theme.main.fonts.application.micro};
  color: ${props => props.theme.main.colors.grey.dark1};
  margin-top: 8px;
  margin-bottom: 32px;
  text-align: center;
`;

const Interest = styled.div`
  ${props => props.theme.main.fonts.application.micro};
  font-weight: bold;
  padding-top: 44px;
  padding-bottom: 44px;
  text-align: center;
  border: 1px solid ${props => props.theme.main.colors.secondary.base};
  margin-bottom: 32px;
  b {
    ${props => props.theme.main.fonts.application.h3};
    color: ${props => props.theme.main.colors.secondary.dark1};
  }
`;

const List = styled.ul`
  padding-left: 0;
  position: relative;  
  padding-top: 32px;
  margin-left: 24px;
  align-self: center;
  li {
    ${props => props.theme.main.fonts.body.normal};
    color: ${props => props.theme.main.colors.grey.dark2};
    padding: 8px 0;    
    padding-left: 14px;
  }
`;

const StyledBox = styled(Box)`
  height: 100%;
  display:flex;
  flex-direction: column;
  margin:8px;
  margin-bottom: 0;
`;

const StyledButton = styled(Button)`
  align-self: center;s
  height: 48px;
  width: 100%;
`;



const BackLink = styled.a`
  ${props => props.theme.main.fonts.body.normal};
  color: ${props => props.theme.main.colors.grey.base};
  margin-top: 32px;
  display: flex;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.main.colors.grey.base};
  }
  svg {
    margin-right: 8px;
  }
  &:hover, &:active {
    color: ${props => props.theme.main.colors.primary.dark1};
    text-decoration: none;
    svg {
      path {
        fill: ${props => props.theme.main.colors.primary.dark1};
      }
    }
  }
  ${breakpoint('md')`
    margin-top: 32px;
  `}
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
`;

const StyledSlider = styled(Slider)`
  margin-left: -10px;
  margin-right: -10px;
  .slick-list {
    overflow: visible;
  }

  .slick-dots { 
    padding-left: 0;
    list-style: none;
    position: absolute;
    top: -28px;
    width: 100%;
    text-align: center;
    li {
      width: 8px;
      height: 8px;
      cursor: pointer;
      position: relative;
      display: inline-block;
      margin: 0 4px;
      padding: 0;

      button {
        font-size: 0;
        line-height: 0;
        display: block;
        padding: 3px;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 6px;
          height: 6px;
          text-align: center;
          content: ' ';
          background-color: ${props => props.theme.main.colors.grey.light2};
          border-radius: 3px;
        }        
      }

      &.slick-active {
        button {
          &:before {
            background-color: ${props => props.theme.main.colors.primary.base};
          }
        }
      }
    }
  }
`;

const StyledHeader = styled(Header)`
  margin-top: 0;
  margin-bottom: 24px;

  ${breakpoint('lg')`
    margin-top: 48px;
  `}
`;

export default () => {
  const dispatch = useDispatch();
  const { loanProducts, isMobile } = useSelector(state => state.reducer);

  const settings = {
    speed: 500,
    centerMode: true,
    centerPadding: '20px',
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    dots: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  } 

  const applyFairFastLoan = () => {
    dispatch(updateLoanType('OnlineFairAndFastLoan'));
    navigate('loan-calculator', { replace: true });
  }

  const applyPersonalLoan = () => {
    dispatch(updateLoanType('OnlineTermLoan'));
    navigate('loan-calculator', { replace: true });
  }

  
  const goToOnlineBanking = (e) => {
    e.preventDefault();
    if(isMobile) {
      window.location.replace('/logout#closeMobile');
    } else {
      window.location.replace(`${process.env.GATSBY_VANCITY_URL}/OnlineBanking/MyAccounts/`);
    }
  }

  const termLoanProduct = loanProducts.find(product => product.loanTypes === 'OnlineTermLoan');
  const ffLoanProduct = loanProducts.find(product => product.loanTypes === 'OnlineFairAndFastLoan');

  return (
    <Row>
      <Col lg={{size: 8, offset: 2}} md={{size: 6, offset: 3}} sm={{size: 8, offset: 2}}>
        <StyledHeader align="center">A simple way to borrow</StyledHeader>    
        <StyledSlider {...settings}>
          {ffLoanProduct ? <div>
            <StyledBox name="container-fairFastLoan">
              <Heading><div>Vancity Fair &amp; Fast Loan<sup>&trade;</sup></div></Heading>
              <Description>An alternative to payday lending. Great for every day expenses or emergency funds.</Description>
              <Amount>$100 <span>to</span> $2,500</Amount>
              <Interest data-testid="interestRate"><b>{ffLoanProduct ? formatPercent(ffLoanProduct.overallInterestRate) : '-'}</b><br /> Fixed Interest Rate <Footnote to="footnote-1">1</Footnote></Interest>
              <StyledButton onClick={applyFairFastLoan} name="button-applyFairFastLoan">Apply online</StyledButton>
              <List>
                <li><b>Credit check not required</b></li>
                <li>Approval based on income</li>
                <li>Up to 2 years to pay it off</li>
                <li>Pay it off early without penalty</li>
              </List>
            </StyledBox>
          </div> :null } 
          {termLoanProduct ? <div>
            <StyledBox name="container-termLoan">
              <Heading>Personal Loan </Heading>
              <Description>Borrow at a low interest rate. Great for large expenses or paying down debt.</Description>
              <Amount>$100 <span>to</span> $10,000</Amount>
              
              <Interest data-testid="interestRate"><b>{termLoanProduct ? formatPercent(termLoanProduct.overallInterestRate) : '-'} (Vancity Prime +{termLoanProduct ? formatPercent(termLoanProduct.additionalInterestRate) : '-'})</b><br /> Variable Interest Rate <Footnote to="footnote-1">1</Footnote></Interest>
              <StyledButton onClick={applyPersonalLoan} name="button-applyTermLoan">Apply online</StyledButton>
              <List>
                <li><b>Credit check required</b></li>
                <li>Approval based on credit score</li>
                <li>Up to 5 years to pay it off</li>
                <li>Pay it off early without penalty</li>
              </List>
            </StyledBox>
          </div> : null}
        </StyledSlider>
        <Row>
          <StyledCol>
            <BackLink onClick={goToOnlineBanking} tabIndex="0"><ExternalLink /> Back to online banking</BackLink>
          </StyledCol>
        </Row>
        <Disclaimer>
          <p id="footnote-1"><sup>1</sup> The annual percentage rate (APR) is compounded monthly, not in advance. APR assumes no fees or charges apply. If additional fees and/or charges apply, the total Cost of Credit and APR would increase. This product, its features and advertised interest rates may change at any time and may be withdrawn by Vancity at any time for any reason without prior notice to the member.</p>
        </Disclaimer>
      </Col>  
    </Row>
  );
}