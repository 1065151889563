import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { Location } from '@reach/router';
import { timeoutLoan, timeoutSet } from 'actions/actions';

import Confirm from 'components/Common/Layout/Confirm';

export default () => {
  const dispatch = useDispatch();
  const { applicationId, cifId, accessToken, isMobile } = useSelector(state => state.reducer);
  const idleTimer = useRef(null);
  const isLoading  = useSelector(state => state.reducer.isLoading);
  const [isIdle, setIsIdle] = useState(false);
  const [timeoutCounter, setTimeoutCounter] = useState(120);
  const [route, setRoute] = useState(null);
  const [timeoutTriggered, setTimeoutTriggered] = useState(false)
  const timeout = 300000;
  let timeoutTimer;
  
  useEffect(() => {
    if(isIdle) {
      if(timeoutCounter > 0) {
        timeoutTimer = setTimeout(() => setTimeoutCounter(timeoutCounter - 1), 1000);
      }
      else {
        loanTimeout();
      }
    }
  }, [isIdle, timeoutCounter]);

  const appIsActive = () => {
    setIsIdle(false);
  };

  const appIsIdle = () => {
    if(route === '/finish') {
      dispatch(timeoutSet());
    }
    else {
      if(!timeoutTriggered && !isMobile) {
        setTimeoutTriggered(true);
        setIsIdle(true);
      }
      else {
        loanTimeout();
      }
    }
  };

  const closeTimeoutPopup = () => {
    idleTimer.current.reset();
    setTimeoutCounter(120);
    clearTimeout(timeoutTimer);
    setIsIdle(false);
  }

  const loanTimeout = () => {
    setIsIdle(false);
    idleTimer.current.pause();
    dispatch(timeoutLoan({
      cifId: cifId,
      applicationId: applicationId,
      eligibilityChecks: {
        IsTimeout: true
      }
    }, accessToken, isMobile));
  }

  useEffect(() => {
    if(route === '' || route === '/error' || route === '/cancel' || route === '/timeout' || route === '/unauthorized') {
      idleTimer.current.pause();
    }
    else {
      idleTimer.current.resume();
    }
  }, [route]);


  useEffect(() => {
    if(isLoading) {
      idleTimer.current.pause();
    }
    else {
      if(route !== '' && route !== '/error' && route !== '/cancel' && route !== '/timeout') {
        idleTimer.current.resume();
      }
    }
  }, [isLoading]);

  useEffect(() => {
    idleTimer.current.reset();
  }, [])



  return (
    <div>
      <IdleTimer ref={idleTimer} onActive={appIsActive} onIdle={appIsIdle} timeout={timeout} startOnLoad stopOnIdle />  
      <Confirm isOpen={isIdle} heading="Your session is about to expire." onClickCancel={closeTimeoutPopup} onClickOk={loanTimeout} timeout>Your session will expire automatically in <b>{new Date(timeoutCounter * 1000).toISOString().substr(15, 4)}</b>. What do you want to do?</Confirm>   
      <Location>
        {({navigate, location}) => {          
          setRoute(location.pathname.replace(/\/+$/, ''));
        }}
      </Location>
    </div>
  );
}