export const formatCurrency = (value, decimals = 2) => {
  if(!(value === null || isNaN(value))) {
    return new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD', minimumFractionDigits: decimals }).format(value);
  }
  return null;
}

export const formatPercent = (value) => {
  if(!(value === null || isNaN(value))) {
    return `${value.toLocaleString('en-CA')}%`;
  }
  return null;
}

export const formatLengthSteps = (minTerm, maxTerm, minTermUnits) => {
  const min = `${minTerm} ${minTermUnits === 0 ? (minTerm === 1 ? `month` : `months`) : (minTerm === 1 ? `week` : `weeks`)}`;
  const max = maxTerm % 12 === 0 ? `${maxTerm/12} ${maxTerm/12 > 1 ? `years` : `year`}` : `${maxTerm} ${(maxTerm === 1 ? `month` : `months`)}`;

  return [min, max];
}