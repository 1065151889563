import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Styled, { ThemeProvider, createGlobalStyle, css } from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import { hideLoading } from 'actions/actions';
import breakpoint from 'styled-components-breakpoint';
import { Location } from '@reach/router';

import '../../fonts/fonts.css';

import LoadingOverlay from './LoadingOverlay/LoadingOverlay';
import ProgressBar from './ProgressBar/ProgressBar';
import MobileHeader from './Header/MobileHeader';
import Timeout from './Timeout/Timeout';
import Authentication from './Authentication/Authentication';

const theme = {
  main: {
    fonts: {
      application: {
        h1: {
          fontFamily: 'Agenda Semibold',
          fontSize: '39.0625px',
          lineHeight: '47px',
        },
        h2: {
          fontFamily: 'Agenda Medium',
          fontSize: '31.25px',
          lineHeight: '37px'
        },
        h3: {
          fontFamily: 'Open Sans',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '30px'
        },        
        micro: {
          fontFamily: 'Open Sans',
          fontSize: '12.8px',
          lineHeight: '19px'
        },
        shoutout: {
          fontFamily: 'Agenda Semibold',
          fontSize: '48.75px',
          lineHeight: '58px'
        }
      },
      desktop: {
        h5: {
          fontFamily: 'Agenda Medium',
          fontSize: '22.4px',
          lineHeight: '27px'
        }
      },
      body: {
        bold: {
          fontFamily: 'Open Sans',
          fontWeight: 'bold',
          fontSize: '16px',
          lineHeight: '24px'
        }, 
        normal: {
          fontFamily: 'Open Sans',
          fontSize: '16px',
          lineHeight: '24px'
        }
      },
      micro: {
        normal: {
          fontFamily: 'Open Sans',          
          fontSize: '12px',
          lineHeight: '18px'
        },
        bold: {
          fontFamily: 'Open Sans', 
          fontWeight: 'bold',         
          fontSize: '12px',
          lineHeight: '18px'
        }
      }
    },
    colors: {
      primary: {
        base: '#CA1905',
        dark1: '#A91504',
        light1: '#D54030'
      },
      secondary: {
        base: '#00A4B3',
        dark1: '#007B86',
        dark2: '#005B63',
        light1: '#40BBC6',
        light2: '#D4FAFF'
      },
      neutral: {
        black: '#000000',
        line: '#E5E5E5',
        highlight: '#F1F1F1',
        offwhite: '#F7F7F7',
        white: '#FFFFFF'
      },
      grey: {
        base: '#776E64',
        dark1: '#645D55',
        dark2: '#49433D',
        light1: '#98928B',
        light2: '#CCC8C3'
      },
      validation: {
        success: '#949900',
        warning: '#F1B434',
        error: '#E0712E',
        inactive: '#C0C0C0'
      }
    }
  },
  breakpoints: { 
    xs: 0,
    sm: 501,
    md: 769,
    lg: 992,
    xl: 1200
  }
};

const GlobalStyles = createGlobalStyle`
  &&& { 
    body {
      background-color: ${props => props.theme.main.colors.neutral.offwhite};

      .modal-backdrop {
        &.show {
          background-color: ${props => props.theme.main.colors.neutral.offwhite};
          opacity: .8;
        }
      }
    }
  }
`;

const StyledRow = Styled(Row)`
  position: relative;
`;

const ContentContainer = Styled.div`
  z-index: 1;
  min-height: ${props => props.colheight}px;
  display: flex;
  flex-direction: row;
  padding: 0 12px;
  width: 100%;
`;

const Sidebar = Styled.div`
  display: none;
  ${breakpoint('sm')` 
    display: block;
    min-width: 200px;
  `}
  ${breakpoint('md')` 
    display: block;
    width: 25%;
    min-width: 250px;
  `}
`;

const Content = Styled.div`
  margin: 0 auto;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  ${breakpoint('md')` 
    width: 50%;
    max-width: 600px;
    min-width: 500px;

  `}
`;

const NoProgressStyledCol = Styled(Col)`
  z-index: 1;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
  margin-top: 32px;
  ${breakpoint('sm')` 
    padding-left: 24px;
    padding-right: 24px;
  `}
  ${breakpoint('lg')` 
    margin-top: 0;
  `}
`;

const StyledContainer = Styled(Container)`
  ${props => props.noprogress === 'true' && css`
    max-width: 1320px;
  `}
`;

export default ({children, displayProgressBar = true}) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.reducer);
  const [progressBarHeight, setProgressBarHeight] = useState(null);
  const [route, setRoute] = useState('');

  const updateProgressBarHeight = (height) => {
    setProgressBarHeight(height);
  }

  useEffect(() => {
    dispatch(hideLoading());
  }, []);

  useEffect(() => {
    if(isLoading) {
      window.scrollTo(0, 0);
    }
  }, [isLoading]);

  useEffect(() => {
    if(route === '' || route === '/error' || route === '/cancel' || route === '/timeout' || route === '/unauthorized' || route === '/finish' || route === '/loan-type') {
      return 
    }
    const beforeUnloadEvent = (event) => { 
      return event.returnValue = `Are you sure you want to leave?`;
    }
    window.addEventListener('beforeunload', beforeUnloadEvent);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadEvent);
    }
  }, [route]);
  
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Location>
          {({navigate, location}) => {          
            setRoute(location.pathname.replace(/\/+$/, ''));
          }}
        </Location>
        <GlobalStyles />   
        <Timeout />
        <Authentication />
        <StyledContainer fluid noprogress={!displayProgressBar ? 'true' : null}>
          {displayProgressBar ?             
            <StyledRow>              
              <MobileHeader />
              <ContentContainer colheight={progressBarHeight}>
                <Sidebar></Sidebar>
                <Content>
                  {isLoading ? <LoadingOverlay /> : children}         
                </Content>         
              </ContentContainer>              
              <ProgressBar updateProgressBarHeight={updateProgressBarHeight} />
            </StyledRow> :            
            <StyledRow> 
              <NoProgressStyledCol>
                {isLoading ? <LoadingOverlay /> : children}
              </NoProgressStyledCol>             
            </StyledRow>}         
        </StyledContainer>
      </div>
    </ThemeProvider>);
}