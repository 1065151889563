import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Location } from '@reach/router';
import breakpoint from 'styled-components-breakpoint';

const LoadingOverlay = styled.div`
  width: 100%;
  height: calc(100vh - 107px);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${breakpoint('md')` 
    height: 100vh;
  `}
`;

const SpinnerContainer = styled.div`
  .dot {
    height: 15px;
    width: 15px;
    background-color: #CA1905;
    display: inline-block;
    margin-right: 7px;
    margin-top: 40px;
    border-radius: 50%;
    transform: scale(0.25);
    animation: preloadAnimate 2s infinite ease-in-out;
  }

  .dot:last-of-type {
    margin-right: 0;
  }

  .dot:nth-child(2) {
    animation-delay: 0.15s;
  }
  .dot:nth-child(3) {
    animation-delay: 0.30s;
  }
  .dot:nth-child(4) {
    animation-delay: 0.45s;
  }
  .dot:nth-child(5) {
    animation-delay: 0.60s;
  }

  @keyframes preloadAnimate {
    50% { 
      transform: scale(1) translateY(-40px);
    }
  }
`;

const Heading = styled.h2`
  ${props => props.theme.main.fonts.application.h2};
  margin-top: 64px;
  margin-bottom: 0;
  text-align: center;
`;

const Description = styled.p`
  ${props => props.theme.main.fonts.body.normal};
  color: ${props => props.theme.main.colors.grey.base};
  margin-top: 32px;
  text-align: center;
`;

export default () => {
  const { loadingType } = useSelector(state => state.reducer);
  const [route, setRoute] = useState(null);
  const loadingMessage = {
    heading: 'Loading',
    description: ''
  }

  if(loadingType) {
    if(loadingType === 'cancel') {
      loadingMessage.heading = 'Cancelling loan application';
      loadingMessage.description = 'Sit tight! This should only take a few seconds.';
    }
  }
  else {
    if(route === '/income-verification') {
      loadingMessage.heading = 'Checking income';
      loadingMessage.description = 'We’re checking your income. This helps us understand your loan eligibility.';
    }
    else if(route === '/summary') {
      loadingMessage.heading = 'Completing loan request';
      loadingMessage.description = 'Sit tight! This should only take a few seconds.';
    }
  }
  return (
    <LoadingOverlay data-testid="loadingOverlay">
      <Location>
        {({navigate, location}) => {          
          setRoute(location.pathname.replace(/\/+$/, ''));
        }}
      </Location>
      <SpinnerContainer>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </SpinnerContainer>
      <Heading>{loadingMessage.heading}</Heading>
      <Description>{loadingMessage.description}</Description>
    </LoadingOverlay>
  );
}