import React from 'react';
import PropTypes from 'prop-types';
import styled  from 'styled-components';
import { Link } from 'react-scroll';

const Sup = styled.sup`
  a {
    cursor: pointer;
    font-weight: bold;
    &:not([href]):not([tabindex]) {
      color: ${props => props.theme.main.colors.secondary.dark1};
    }
  }
`;
const Footnote = ({to, children}) => {
  return (<Sup><Link to={to} smooth={true}>{children}</Link></Sup>);
}

Footnote.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired
}

export default Footnote;