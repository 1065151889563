import { UPDATE_STATE, UPDATE_CUSTOMER_DETAIL, UPDATE_RELATIONSHIPS, UPDATE_LOAN_PRODUCTS, START_LOADING, STOP_LOADING, EDIT_DEMOGRAPHIC_DETAIL, SELECT_INCOME_ACCOUNT, SET_APPLICATION_DATA, UPDATE_LOAN_CALCULATION, RESET_LOAN_CALCULATION, UPDATE_PRE_QUALIFICATION, UPDATE_LOAN_TYPE, UPDATE_REQUIREMENTS, UPDATE_LOAN_ACCOUNT, SAVE_LOAN_CALCULATION, RESET_LOAN_TYPE, UPDATE_LOAN_APPROVAL, UPDATE_INDUSTRY_LIST, UPDATE_SECTOR_LIST, UPDATE_CONSENT_DATETIME, SET_SERVER_DATETIME, SET_TIMEOUT, RESET_STATE, SET_TOKEN } from './actionTypes';
import { navigate } from 'gatsby';
import { handleResponse, handleError, catchError } from 'utils/response';

export const showLoading = (loadingType = null) => {
  return (dispatch) => {
    dispatch({
      type: START_LOADING,
      loadingType: loadingType
    })
  }
};

export const hideLoading = () => {
  return (dispatch) => {
    dispatch({
      type: STOP_LOADING
    });
  }
};

export const updateState = (payload) => {  
  return (dispatch) => {
    dispatch({
      type: UPDATE_STATE,
      payload: payload
    });
  }
}


export const resetState = () => {  
  return (dispatch) => {
    dispatch({
      type: RESET_STATE
    });
  }
}


export const getCustomerDetails = (token, isMobile = false, callback = () => {}) => {
  return (dispatch) => {
    dispatch(showLoading());

    fetch(`${process.env.GATSBY_BACKEND_URL}/${(isMobile ? 'Mobile/' : '')}MemberInfo`, {
      credentials: 'include',     
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(handleResponse).then(response => {
      if(response.errorAlert && response.errorAlert.isError) {
        handleError(response);
      }
      else {
        dispatch({
          type: SET_APPLICATION_DATA,
          applicationId: response.applicationId,
          pan: '',
          cifId: response.cifId   
        })
        dispatch({
          type: UPDATE_CUSTOMER_DETAIL,
          payload: response.member.partyRecord.person
        });
        dispatch({
          type: UPDATE_RELATIONSHIPS,
          payload: response.relationships
        });
        dispatch({
          type: UPDATE_PRE_QUALIFICATION,
          payload: response.qualify
        });
        dispatch({
          type: UPDATE_LOAN_PRODUCTS,
          payload:response.loanProducts
        });
        dispatch({
          type: SET_TOKEN,
          payload: token
        });
        callback();
      }
    }).catch(catchError);
  }
}


export const editDemographicDetails = (edit) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_DEMOGRAPHIC_DETAIL,
      edit: edit
    });
  }
}

export const updateCustomerDetails = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CUSTOMER_DETAIL,
      payload: payload
    });
  }
}

export const selectIncomeAccount = (incomeAccountId) => {
  return (dispatch) => {
    dispatch({
      type: SELECT_INCOME_ACCOUNT,
      incomeAccountId: incomeAccountId
    });
  }
}

export const updateLoanType = (loanType) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOAN_TYPE,
      loanType: loanType
    });
  }
}

export const updateLoanCalculation = (loanCalculatorRequest, token, isMobile = false, save = false) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOAN_CALCULATION,
      payload: loanCalculatorRequest.calculation.request
    });
    fetch(`${process.env.GATSBY_BACKEND_URL}/${(isMobile ? 'Mobile/' : '')}CalculateLoan`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(loanCalculatorRequest)
      }
    ).then(handleResponse).then(response => {
      dispatch({
        type: UPDATE_LOAN_CALCULATION,
        payload: response.calculation.result
      });
      if(save) {
        dispatch({
          type: SAVE_LOAN_CALCULATION
        });
      }
    }).catch(catchError);
  }
}

export const updateLoan = (loan) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOAN_CALCULATION,
      payload: loan
    });
  }
}

export const resetCalculator = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_LOAN_CALCULATION
    });
  }
}

export const incomeAdjudication = (verifyIncomeRequest, token, isMobile = false) => {
  return (dispatch) => {
    dispatch(showLoading());
    fetch(`${process.env.GATSBY_BACKEND_URL}/${(isMobile ? 'Mobile/' : '')}IncomeBasedAdjudication`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(verifyIncomeRequest)
    }
    ).then(handleResponse).then(response => {
      if(response.errorAlert && response.errorAlert.isError) {
        handleError(response);
      }
      else {
        const loanApproval = response.loanApproval;

        if(loanApproval.needsCreditBureauConsent) {
          navigate('credit-check', { replace: true });
        }
        else {
          dispatch({
            type: UPDATE_LOAN_APPROVAL,
            payload: loanApproval
          });
          navigate('eligibility', { replace: true });
        }
      }
    }).catch(catchError);
  }
}

export const creditAdjudication = (creditCheckRequest, token, isMobile = false) => {
  return (dispatch) => {
    dispatch(showLoading());
    fetch(`${process.env.GATSBY_BACKEND_URL}/${(isMobile ? 'Mobile/' : '')}CreditBasedAdjudication`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(creditCheckRequest)
    }
    ).then(handleResponse).then(response => {
      if(response.errorAlert && response.errorAlert.isError) {
        handleError(response);
      }
      else {
        const loanApproval = response.loanApproval;

        dispatch({
          type: UPDATE_LOAN_APPROVAL,
          payload: loanApproval
        });
        navigate('eligibility', { replace: true });
      }
    }).catch(catchError);
  }
}

export const updateRequirements = (requirements) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_REQUIREMENTS,
      payload: requirements
    });
  }
}

export const fundLoan = (fundLoanRequest, token, isMobile = false) => {
  return (dispatch) => {
    dispatch(showLoading());
    fetch(`${process.env.GATSBY_BACKEND_URL}/${(isMobile ? 'Mobile/' : '')}FundLoan`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(fundLoanRequest)
    }
    ).then(handleResponse).then(response => {
      if(response.errorAlert && response.errorAlert.isError) {
        handleError(response);
      }
      else {
        dispatch({
          type: UPDATE_LOAN_ACCOUNT,
          payload: response.loanSummary
        });
        navigate('finish', { replace: true });
      }
    }).catch(catchError);
  }
}

export const saveLoanCalculator = () => {
  return (dispatch) => {
    dispatch({
      type: SAVE_LOAN_CALCULATION
    });
  }
}

export const resetLoanType = () => {  
  return (dispatch) => {
    dispatch({
      type: RESET_LOAN_TYPE
    });
  }
}

export const getIndustryList = (token, isMobile = false) => {
  return (dispatch) => {
    fetch(`${process.env.GATSBY_BACKEND_URL}/${(isMobile ? 'Mobile/' : '')}NocList`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({})
    })
    .then(handleResponse)
    .then(response => {      
      dispatch({
        type: UPDATE_INDUSTRY_LIST,
        payload: response.Data
      });
    }).catch(catchError);
  }
}


export const getSectorList = (industry, token, isMobile = false) => {
  return (dispatch) => {
    fetch(`${process.env.GATSBY_BACKEND_URL}/${(isMobile ? 'Mobile/' : '')}NocList`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        "RefinedIndustry": industry
      })
    })
    .then(handleResponse)
    .then(response => {
      dispatch({
        type: UPDATE_SECTOR_LIST,
        industry: industry,
        payload: response.Data
      });
    }).catch(catchError);
  }
}

export const updateEligibility = (eliqibilityRequest, token, isMobile = false) => {
  return (dispatch) => {
    fetch(`${process.env.GATSBY_BACKEND_URL}/${(isMobile ? 'Mobile/' : '')}EligibilityChecks`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(eliqibilityRequest)
    });
  }
}

export const getServerDatetime = (datetimeRequest, token, isMobile = false) => {
  return (dispatch) => {
    fetch(`${process.env.GATSBY_BACKEND_URL}/${(isMobile ? 'Mobile/' : '')}ServerDateTimes`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(datetimeRequest)
    }
    ).then(handleResponse).then(response => {
      dispatch({
        type: SET_SERVER_DATETIME,
        payload: response.firstPaymentDateSource
      });  
    }).catch(catchError);
  }
}

export const setConsentDatetime = (datetimeRequest, token, isMobile = false) => {
  return (dispatch) => {
    fetch(`${process.env.GATSBY_BACKEND_URL}/${(isMobile ? 'Mobile/' : '')}ServerDateTimes`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(datetimeRequest)
    }
    ).then(handleResponse).then(response => {
        dispatch({
          type: UPDATE_CONSENT_DATETIME,
          payload: response.generalDateSource
        });      
    }).catch(catchError);
  }
}

export const cancelLoan = (cancelRequest, token, isMobile = false) => {
  return (dispatch) => {
    dispatch(showLoading('cancel'));
    fetch(`${process.env.GATSBY_BACKEND_URL}/${(isMobile ? 'Mobile/' : '')}EligibilityChecks`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(cancelRequest)
    }).then(() => {
      navigate('cancel', { replace: true });
    }).catch(() => {
      navigate('cancel', { replace: true });
    });
  }
}

export const timeoutLoan = (timeoutRequest, token, isMobile = false) => {
  return (dispatch) => {
    dispatch(showLoading('cancel'));
    fetch(`${process.env.GATSBY_BACKEND_URL}/${(isMobile ? 'Mobile/' : '')}EligibilityChecks`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(timeoutRequest)
    }).then(() => {
      navigate('timeout', { replace: true });
    }).catch(() => {
      navigate('timeout', { replace: true });
    });
  }
}

export const timeoutSet = () => {
  return (dispatch) => {
    dispatch({
      type: SET_TIMEOUT
    });
  }
};

export const setToken = (token) => {
  return (dispatch) => {
    dispatch({
      type: SET_TOKEN,
      payload: token
    })
  }
}