import React from 'react';
import Styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Wordmark from 'images/wordmark.inline.svg';

const Heading = Styled.div`
  align-self: flex-start;
  display: flex;
  width: 100%;
  ${breakpoint('sm')` 
    padding: 32px 9px 32px 0;
  `}
  ${breakpoint('md')` 
    padding: 32px 17px 32px 0;
  `}
`;

const StyledWordmark = Styled(Wordmark)`
  margin-left: auto;
  width: 110px;
`;

export default () => {
  return (
    <Heading>
      <StyledWordmark />
    </Heading>);
}