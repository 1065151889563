import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Button from 'components/Common/Form/Button';

const StyledModal = styled(Modal)`
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  ${breakpoint('sm')` 
    margin-left: auto;
    margin-right: auto;
    flex: 0 0 66.666667%;
    max-width: 650px;
  `}
  .modal-content {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    border: none;
    border-radius: 0;

    .modal-header {
      border-bottom: none;
      padding: 24px;
      padding-bottom: 0;
      .modal-title {
        ${props => props.theme.main.fonts.application.h2};
      }
    }
    .modal-body {
      padding: 24px;
      ${props => props.theme.main.fonts.body.normal};
    }
    .modal-footer {
      border-top: none;
      padding: 24px;
      padding-top: 0;
      button {
        width: 100%;
      }
      flex-direction: column;
      &>:not(:last-child) {
        margin-right: 0;
        margin-bottom: 16px;
      }
      &>:not(:first-child) {
        margin-left: 0;
      }
      ${breakpoint('md')` 
        flex-direction: row;
        &>:not(:last-child) {
          margin-right: 8px;
          margin-bottom: 0;
        }
        &>:not(:first-child) {
          margin-left: 8px;
        }
      `}
    }
  }
`;

export default ({children, heading, isOpen = false, onClickCancel, onClickOk, timeout = false }) => {
  return (
    <StyledModal isOpen={isOpen} centered={true}>
      <ModalHeader>{heading}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      {timeout ? 
        <ModalFooter>
          <Button onClick={onClickCancel} name="button-continue">Continue application</Button>
          <Button color="secondary" onClick={onClickOk} name="button-exit">Exit application</Button>
        </ModalFooter>
      :
        <ModalFooter>
          <Button color="secondary" onClick={onClickCancel} name="button-back">No, back to application</Button>
          <Button onClick={onClickOk} name="button-cancel">Yes, cancel application</Button>
        </ModalFooter> }
    </StyledModal>);
}