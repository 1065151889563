import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDetails, updateState } from 'actions/actions';
import { navigate } from 'gatsby';

export default () => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector(state => state.reducer);

  useEffect(() => {
    if(!accessToken) {
      let hash = window.location.hash;
      const isMobile = window.location.search === '?mobile';
        
      if (hash.startsWith("#")) {
          hash = hash.substring(1);
      }

      let access_token = "";
      let id_token = "";
      let state = "";

      const parts = hash.split("&");
      parts.forEach(function (element) {
          var tokens = element.split("=");
          if (tokens[0] === "access_token") {
              access_token = tokens[1];
          }
          else if (tokens[0] === "id_token") {
              id_token = tokens[1];
          }
          else if (tokens[0] === "state") {
              state = tokens[1];
          }
      });

      if (access_token.length > 0) {
          navigate('/', { replace: true });  
          dispatch(updateState({
            isMobile: isMobile
          }));
          dispatch(getCustomerDetails(access_token, isMobile, () => {
            navigate('loan-type', { replace: true });    
          }));
      }
      else if(!window.location.pathname.startsWith('/error') && !window.location.pathname.startsWith('/unauthorized') && !window.location.pathname.startsWith('/cancel')) {
        navigate('unauthorized', { replace: true }); 
      }
    }
  },[]);
  
  return (<div></div>);
}