import React  from 'react';
import { useSelector } from 'react-redux';
import Styled from 'styled-components';

import breakpoint from 'styled-components-breakpoint';

const ProgressBar = Styled.div`
  flex-grow: 1;
  text-align: right;
  margin-bottom: 40px;

  ${breakpoint('sm')` 
    padding-right: 9px;
  `}
  ${breakpoint('md')` 
    padding-right: 17px;
  `}
  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      padding: 15px 0;
      ${props => props.theme.main.fonts.body.normal};
      color: ${props => props.theme.main.colors.grey.base};
      &.selected {
        ${props => props.theme.main.fonts.body.bold};
        color: black;
        position: relative;        
        &:after {
          position:absolute;
          top: 0;
          background-color: ${props => props.theme.main.colors.primary.base};
          width: 4px;
          height: 56px;
          content: ' ';
          ${breakpoint('sm')` 
            right: -24px;
          `}
          ${breakpoint('md')` 
            right: -32px;
          `}
        }
      }
    }
  }
`;

export default ({route}) => {
  const loanType = useSelector(state => state.reducer.loanType);

  const steps = loanType === 'OnlineTermLoan' ? [
    {
      name: 'Loan calculator',
      route: '/loan-calculator'
    },
    {
      name: 'Requirements',
      route: '/requirements'
    },
    {
      name: 'Income check',
      route: '/income-verification'
    },
    {
      name: 'Credit check',
      route: '/credit-check'
    },
    {
      name: 'Eligibility',
      route: '/eligibility'
    },
    {
      name: 'Employment',
      route: '/employment'
    },
    {
      name: 'Summary & terms',
      route: '/summary'
    },
    {
      name: 'Finish',
      route: '/finish'
    }
  ] :
  [
    {
      name: 'Loan calculator',
      route: '/loan-calculator'
    },
    {
      name: 'Requirements',
      route: '/requirements'
    },
    {
      name: 'Income check',
      route: '/income-verification'
    },
    {
      name: 'Eligibility',
      route: '/eligibility'
    },
    {
      name: 'Employment',
      route: '/employment'
    },
    {
      name: 'Summary & terms',
      route: '/summary'
    },
    {
      name: 'Finish',
      route: '/finish'
    }  
  ];

  return (
    <ProgressBar data-testid="progressBar">
      <ul>
        {steps.map((step, i) => 
          <li className={route === step.route ? "selected" : null} key={i}>{step.name}</li>)
        }      
      </ul>
    </ProgressBar>);
}