import React from 'react';

import Layout from 'components/Layout/Layout';
import LoanType from 'components/LoanType/LoanType';
import SEO from 'components/seo';

const IndexPage = () => (
  <Layout displayProgressBar={false}>
    <SEO title="Select loan type" />
    <LoanType />
  </Layout>
)

export default IndexPage
