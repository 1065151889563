import React from 'react';
import PropTypes from 'prop-types';
import styled  from 'styled-components';

const StyledDisclaimer = styled.div`
  p {
    ${props => props.theme.main.fonts.application.micro};
    color: ${props => props.theme.main.colors.grey.base};
  }
`;
const Disclaimer = ({children}) => {
  return (<StyledDisclaimer>{children}</StyledDisclaimer>);
}

Disclaimer.propTypes = {
  children: PropTypes.node.isRequired
}

export default Disclaimer;