import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { css }  from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Wordmark from 'images/wordmark.inline.svg';
import HelpIcon from 'images/help.inline.svg';
import CrossIcon from 'images/cross.inline.svg';
import Exit from 'images/exit.inline.svg';
import Phone from 'images/phone.inline.svg';

import Cancel from 'components/Common/Cancel/Cancel';

const HeaderContainer  = styled.div`
  width: 100%;
  padding-top: 57px;
  ${breakpoint('sm')` 
    display: none;
  `}
`;

const Header = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 57px;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 2;
`;

const ProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  background-color: ${props => props.theme.main.colors.neutral.highlight};
  left: 0;
`;

const Bar = styled.div`
  width: ${props => props.progress}%;
  height: 4px;
  background-color: ${props => props.theme.main.colors.primary.base};
  transition: width 500ms;
`;

const ProgressInfo = styled.div`
   ${props => props.theme.main.fonts.body.normal};
  color: ${props => props.theme.main.colors.grey.base};
  padding: 16px 24px; 
`;


const Help = styled.a`
  position: absolute;
  right: 26px;
  top: 16px;
  cursor: pointer;
  svg {
    path {
      fill: ${props => props.theme.main.colors.grey.base};
    }
  }
`;

const StyledWordmark = styled(Wordmark)`
  width: 102.54px;
  height: 31px;
`;

const HelpSlider = styled.div`
  background-color: ${props => props.theme.main.colors.neutral.white};
  position: fixed;
  height: 100%;
  width: calc(100% - 24px);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 3;
  top: 0;
  right: calc(-100% + 20px);
  transition: right 500ms;
  ${props => props.open && css`
    right: 0;
  `}
  h4 {
    ${props => props.theme.main.fonts.application.h3};
    color: black;
    padding-bottom: 8px;
    margin-top: 80px;
    margin-left: 24px;
  }
  ul {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
    margin-left: 24px;

    li {
      padding: 12px 0;
      a {
        ${props => props.theme.main.fonts.body.normal};
        color: ${props => props.theme.main.colors.grey.base};
        cursor: pointer;
        &:hover {
          text-decoration: none;
        }
      }
      &:last-of-type {
        padding-bottom: 28px;
      }
      svg {
        margin-right: 8px;
        path {
          fill: ${props => props.theme.main.colors.primary.base};
        }
      }
    }
  }
`;

const Close = styled(CrossIcon)`
  position: absolute;
  top: 21px;
  right: 21px;
  cursor: pointer;
`;

export default () => {
  const { loanType, currentRoute, isLoading, isMobile } = useSelector(state => state.reducer);
  const [exitOpen, setExitOpen] = useState(false);
  const [displayHelp, setDisplayHelp] = useState(false);

  const steps = loanType === 'OnlineTermLoan' ? [
    { route: '/loan-calculator' },
    { route: '/requirements' },
    { route: '/income-verification' },
    { route: '/credit-check' },
    { route: '/eligibility' },
    { route: '/employment' },
    { route: '/summary' },
    { route: '/finish' }
  ] :
  [
    { route: '/loan-calculator' },
    { route: '/requirements' },
    { route: '/income-verification' },
    { route: '/eligibility' },
    { route: '/employment' },
    { route: '/summary' },
    { route: '/finish' }  
  ];  

  const stepNumber = steps.findIndex(step => step.route === currentRoute) + 1;
  const totalSteps = steps.length;

  const exitApplication = (event) => {
    event.preventDefault();
    setExitOpen(true);
  }

  useEffect(() => {
    if(isLoading) {
      setDisplayHelp(false);
    }
  }, [isLoading]);

  return (<HeaderContainer>
            <Header>
              <StyledWordmark /> 
              <Help onClick={() => setDisplayHelp(true)}>
                <HelpIcon />
              </Help>
              <ProgressBar>
                <Bar progress={(stepNumber / totalSteps) * 100} />
              </ProgressBar>
            </Header>
            <ProgressInfo>Step {stepNumber} of {totalSteps}</ProgressInfo>
            <HelpSlider open={displayHelp}>
              <Close onClick={() => setDisplayHelp(false)} />
              <Cancel confirmOpen={exitOpen} setConfirmOpen={setExitOpen} />
              <h4>Need help?</h4>
              <ul>
                <li><a href="tel:18888262489" tabIndex="0"> <Phone /> 1-888-826-2489</a></li>
                <li>
                {isMobile ? 
                  <a href={window.location.pathname + '#openFAQ'} tabIndex="0"><HelpIcon /> FAQs</a> : 
                  <a href={`${process.env.GATSBY_VANCITY_URL}/Loans/TypesOfLoans/FairAndFastLoan/FAQs/`} tabIndex="0" target="_blank"><HelpIcon /> FAQs</a>
                  }
                </li>
                {currentRoute !== '/finish' ? <li><a onClick={exitApplication} tabIndex="0"><Exit /> Exit Application </a></li> : null}
              </ul>
            </HelpSlider>
          </HeaderContainer>);
}