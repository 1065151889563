import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cancelLoan } from 'actions/actions';

import Confirm from 'components/Common/Layout/Confirm';

export default ({confirmOpen, setConfirmOpen}) => {
  const dispatch = useDispatch();
  const { applicationId, cifId, accessToken, isMobile } = useSelector(state => state.reducer);

  const cancelLoanApplication = () => {    
    setConfirmOpen(false)
    dispatch(cancelLoan({
      cifId: cifId,
      applicationId: applicationId,
      eligibilityChecks: {
        IsCancel: true
      }
    }, accessToken, isMobile));
  }

  return(<Confirm isOpen={confirmOpen} heading="Are you sure you want to cancel your loan application?" onClickCancel={() => setConfirmOpen(false)} onClickOk={cancelLoanApplication}>Your details will not be saved once you have cancelled.</Confirm>)
}