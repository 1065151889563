import React, { useState } from 'react';
import Styled from 'styled-components';

import Exit from 'images/exit.inline.svg';
import Phone from 'images/phone.inline.svg';
import Help from 'images/help.inline.svg';
import breakpoint from 'styled-components-breakpoint';

import Cancel from 'components/Common/Cancel/Cancel';

const HelpContainer = Styled.div`
  align-self: flex-end;
  text-align: right;
  ${breakpoint('sm')` 
    padding-right: 9px;
  `}
  ${breakpoint('md')` 
    padding-right: 17px;
  `}
  h4 {
    ${props => props.theme.main.fonts.body.bold};
    color: black;
    padding-bottom: 8px;
  }
  ul {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;

    li {
      padding: 8px 0;
      a {
        ${props => props.theme.main.fonts.body.normal};
        color: ${props => props.theme.main.colors.grey.base};
        cursor: pointer;
        &:hover {
          text-decoration: none;
        }
      }
      &:last-of-type {
        padding-bottom: 28px;
      }
    }
  }
  svg {
    margin-left: 3px;
    path {
      fill: ${props => props.theme.main.colors.primary.base};
    }
  }
  position: absolute;
  bottom: 0;
  right: 15px;

`;

export default ({route}) => {
  const [exitOpen, setExitOpen] = useState(false);
  const exitApplication = (event) => {
    event.preventDefault();
    setExitOpen(true);
  }

  return (
    <HelpContainer>
      <Cancel confirmOpen={exitOpen} setConfirmOpen={setExitOpen} />
      <h4>Need help?</h4>
      <ul>
        <li><a href="tel:18888262489" tabIndex="0">1-888-826-2489 <Phone /></a></li>
        <li><a href={`${process.env.GATSBY_VANCITY_URL}/Loans/TypesOfLoans/FairAndFastLoan/FAQs/`} tabIndex="0" target="_blank">FAQs <Help /></a></li>
        {route !== '/finish' ? <li><a onClick={exitApplication} tabIndex="0">Exit Application <Exit /></a></li> : null}
      </ul>
    </HelpContainer>);
}